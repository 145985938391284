<template>
  <div class="w-full">
    <div class="w-full flex flex-col border bg-white rounded min-h-96">
      <div
        class="grid grid-cols-1 lg:grid-cols-2 space-y-2 md:space-y-0 md:space-x-2 bg-white min-h-96 p-4"
      >
        <div class="cols-span-1 space-y-6 h-full">
          <h3 class="text-sm font-semibold">Erfassen Sie Ihre Firmenstammdaten</h3>

          <div class="flex">
            <div class="w-full">
              <input
                type="text"
                v-model="company.address"
                placeholder="Adresse"
                class="border rounded px-2 py-1.5 w-full text-xs appearance-none focus:outline-none bg-transparent"
              />
            </div>
          </div>

          <div class="flex space-x-2 space-x-2">
            <div class="w-1/4">
              <input
                type="text"
                v-model="company.postcode"
                placeholder="Postleitzahl"
                class="border rounded px-2 py-1.5 w-full text-xs appearance-none focus:outline-none bg-transparent"
              />
            </div>
            <div class="w-3/4">
              <input
                type="text"
                v-model="company.place"
                placeholder="Ort"
                class="border rounded px-2 py-1.5 w-full text-xs appearance-none focus:outline-none bg-transparent"
              />
            </div>
          </div>

          <div class="flex space-x-2">
            <div class="w-1/2">
              <input
                type="text"
                v-model="company.phone"
                placeholder="Telefon"
                class="border rounded px-2 py-1.5 w-full text-xs appearance-none focus:outline-none bg-transparent"
              />
            </div>

            <div class="w-1/2">
              <form-select
                v-model="company.legal_form"
                :options="options"
                placeholder="Rechtsform"
              />
            </div>
          </div>
        </div>

        <div class="cols-span-1 space-y-4 h-full">
          <h3 class="text-sm font-semibold">Firmenlogo</h3>

          <div class="relative overflow-hidden h-48">
            <div class="w-full h-full">
              <div
                class="w-full h-full relative z-10 bg-gray-50 rounded bg-no-repeat bg-contain bg-center"
                :style="
                  `background-image: url(${(company && company.logo && company.logo.url) ||
                    profile.org.logo})`
                "
              >
                <div
                  v-show="!(company && company.logo && company.logo.url) && !profile.org.logo"
                  class="absolute inset-0 border-2 border-gray-300 border-dashed m-4 rounded"
                >
                  <p class="text-center text-gray-400 mx-auto mt-3">
                    Dateien hierher ziehen und loslassen
                  </p>
                </div>
              </div>
              <input
                type="file"
                class="absolute inset-0 border w-full opacity-0 z-20"
                @change="e => (company.logo = onFileChange(e))"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex justify-start space-x-2 text-xs text-white px-2 py-2 border-t bg-gray-100 border-gray-400"
      >
        <a href="#" @click.prevent="save" class="rounded-sm bg-blue-600 px-2 py-1">Speichern</a>
      </div>
    </div>
  </div>
</template>

<script>
import { updateOrg } from '@/services/organization'

export default {
  name: 'settings-edit',
  props: ['value'],
  components: {},
  data() {
    return {
      company: {
        address: null,
        postcode: null,
        place: null,
        phone: null,
        legal_form: '',
        logo: {},
      },
      blob: null,
      options: [
        { label: 'Einzelfirma', value: 'Einzelfirma' },
        { label: 'Personengesellschaft', value: 'Personengesellschaft' },
        { label: 'Stiftung', value: 'Stiftung' },
        { label: 'Aktiengesellschaft (AG)', value: 'Aktiengesellschaft (AG)' },
        {
          label: 'Gesellschaft mit beschränkter Haftung (GmbH)',
          value: 'Gesellschaft mit beschränkter Haftung (GmbH)',
        },
        { label: 'Verein', value: 'Verein' },
      ],
      breadcrumbs: {
        title: [
          { crumb: 'Allgemeine Einstellungen', link: '/settings' },
          { crumb: 'Firmenprofil' },
        ],
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$emit('breadcrumbs', this.breadcrumbs)

      this.company = {
        ...this.company,
        ...this.profile.org,
      }
    },
    save() {
      let logo = null

      if (this.company.logo) logo = this.company.logo.file

      updateOrg({ ...this.company, logo })
        .then(response => {
          this.updateProfile({
            ...this.profile,
            org: { ...response.data.data },
          })
          // this.$toast.success(response.data.message)
          this.$router.push('/settings')
        })
        .catch(() => {})
    },
  },
}
</script>

<style></style>
